import React, { ComponentType, useMemo, useState } from 'react';

import classNames from 'classnames';

import { MIXPANEL_EVENT_NAMES, MixpanelEventName } from '@shared/api/mixPanel';
import { useNavLayout } from '@shared/common/components/NavLayout/NavLayout';
import { useMixPanel } from '@shared/common/providers/MixPanelProvider';
import { GENERAL_URLS, nextProjectUrl } from '@shared/constants/urls';
import { useFeatureToggles } from '@shared/hooks/useFeatureToggles';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Login, Menu2 } from '@ui/uikit/components/icons/mono';
import { FeatureBoost } from '@ui/uikit/components/icons/poly/FeatureBoost';
import { FeatureLaunchpad } from '@ui/uikit/components/icons/poly/FeatureLaunchpad';
import { FeatureMagicStore } from '@ui/uikit/components/icons/poly/FeatureMagicStore';
import { FeatureStaking } from '@ui/uikit/components/icons/poly/FeatureStaking';
import { FeatureSwap } from '@ui/uikit/components/icons/poly/FeatureSwap';
import { Dao } from '@ui/uikit/components/logos/Dao';
import { Otc } from '@ui/uikit/components/logos/Otc';
import { cn } from '@ui/uikit/utils';

import { BuySqrButton } from './BuySqrButton';
import BuySqrDialog from './BuySqrDialog';
import { buySqrNavItems } from './constants';
import NavLayoutLink from './NavLayoutLink';
import UserAvatar from './UserAvatar';

const PRODUCT_ID_TO_MIXPANEL_EVENT_MAP: Record<string, MixpanelEventName> = {
  launchpad: MIXPANEL_EVENT_NAMES.LAUNCHPAD_LINK_CLICK,
  staking: MIXPANEL_EVENT_NAMES.STAKING_LINK_CLICK,
  otc: MIXPANEL_EVENT_NAMES.OTC_LINK_CLICK,
};

interface ProductsMenuButtonProps {
  onLogin?: () => void;
}

interface ProductMenuItem {
  id: string;
  label: string;
  Icon: ComponentType<{ className?: string }>;
  href: string;
  external?: boolean;
  isAuthRequired?: boolean;
  isHidden?: boolean;
}

const ProductsMenuButton = ({ onLogin }: ProductsMenuButtonProps) => {
  const { user } = useNavLayout();

  const [isBuySqrOpen, setIsBuySqrOpen] = useState(false);
  const itemClassName =
    'flex flex-col focus:outline-0 items-center transition-all justify-center text-center gap-3 rounded-md bg-card-default-default-bg hover:bg-base-bg-secondary focus:bg-base-bg-secondary focus:relative aspect-square text-caption-sm-strong-m md-msq:rounded-lg md-msq:gap-2 md-msq:text-body-md-strong-m';
  const iconClassName = 'size-10';

  const { track } = useMixPanel();

  const { enableMagicBoostApp } = useFeatureToggles();

  const products: ProductMenuItem[] = useMemo(() => {
    const allItems: ProductMenuItem[] = [
      {
        id: 'magic-store',
        label: 'Store',
        Icon: FeatureMagicStore,
        href: nextProjectUrl.store('/'),
      },
      {
        id: 'launchpad',
        label: 'Launchpad',
        Icon: FeatureLaunchpad,
        href: nextProjectUrl.launchpad('/'),
      },
      {
        id: 'staking',
        label: 'Staking',
        Icon: FeatureStaking,
        href: nextProjectUrl.staking('/'),
      },
      {
        id: 'swap',
        label: 'Swap',
        Icon: FeatureSwap,
        href: process.env.NEXT_PUBLIC_SWAP_HOST || 'https://magicsquare.io/swap',
      },
      {
        id: 'boost',
        label: 'Boost',
        Icon: FeatureBoost,
        href: nextProjectUrl.store('/magic-boost'),
        isAuthRequired: true,
        isHidden: !enableMagicBoostApp,
      },
      {
        id: 'otc',
        label: 'OTC',
        Icon: Otc,
        href: GENERAL_URLS.otc,
        external: true,
      },
      {
        id: 'dao',
        label: 'DAO',
        Icon: Dao,
        href: GENERAL_URLS.dao,
        external: true,
      },
    ];

    return allItems.filter(({ isHidden }) => !isHidden);
  }, [enableMagicBoostApp]);

  const handleTrackedLinkClick = (eventName: MixpanelEventName) => {
    const userId = user?.data?.id;

    if (userId) {
      track(eventName, {
        distinct_id: userId,
      });
    }
  };

  return (
    <>
      <DropdownMenu.Root
        // NOTE: fixes the issue when css property "pointer-events: none;" still set on body
        // when dialog is opened from dropdown
        modal={false}
      >
        <DropdownMenu.Trigger className="msq-btn msq-btn-icon-md size-[2.25rem] msq-btn-outline !rounded-full md-msq:msq-btn-icon-lg">
          <Menu2 className="msq-btn-icon-child-md" />
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            align="end"
            className="z-10 msq-sub-menu p-4 mt-1 ml-4 grid grid-cols-3 w-[min(calc(100vw-2rem),20.5rem)] md-msq:w-[20.75rem] max-h-[calc(100vh-4.5rem)] overflow-y-auto data-[state=open]:animate-show-menu data-[state=closed]:animate-hide-menu"
          >
            <div
              className={classNames(
                'p-2 pb-6 col-span-3 shadow-inset-border-bottom shadow-base-border-light mb-4',
                // NOTE: on larger screen we are showing this button in header
                'md-msq:hidden',
              )}
            >
              <DropdownMenu.Item
                onClick={() => {
                  setIsBuySqrOpen(true);
                }}
                asChild
              >
                <BuySqrButton
                  label="Buy & Sell SQR"
                  className="text-caption-sm-strong-m w-full focus:outline-none"
                />
              </DropdownMenu.Item>
            </div>

            <DropdownMenu.Item asChild>
              {user ? (
                <NavLayoutLink
                  className={cn(itemClassName, 'group')}
                  href={nextProjectUrl.store('/profile/magic-id')}
                >
                  <UserAvatar
                    src={user.data.attributes.icon}
                    membershipTier={user.data.membershipTier.attributes.key}
                    className={cn(
                      iconClassName,
                      'msq-avatar-md group-hover:[&>.msq-avatar-img]:bg-base-bg [&>.msq-avatar-img]:transition-colors',
                    )}
                  />
                  Magic ID
                </NavLayoutLink>
              ) : (
                <button onClick={() => onLogin?.()} className={cn(itemClassName, 'group')}>
                  <span
                    className={cn(
                      iconClassName,
                      'rounded-full overflow-hidden flex items-center justify-center transition-all bg-base-bg-secondary group-hover:bg-base-bg',
                    )}
                  >
                    <Login className="size-3 md-msq:size-4" />
                  </span>
                  Magic ID
                </button>
              )}
            </DropdownMenu.Item>

            {products.map(({ id, Icon, isAuthRequired, href, external, label }) => {
              const mixpanelEventName = PRODUCT_ID_TO_MIXPANEL_EVENT_MAP[id];

              return (
                <DropdownMenu.Item key={id} asChild>
                  <NavLayoutLink
                    isAuthRequired={isAuthRequired}
                    href={href}
                    className={itemClassName}
                    onClick={() => mixpanelEventName && handleTrackedLinkClick(mixpanelEventName)}
                    external={external}
                  >
                    <Icon className={iconClassName} />
                    {label}
                  </NavLayoutLink>
                </DropdownMenu.Item>
              );
            })}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
      <BuySqrDialog items={buySqrNavItems} open={isBuySqrOpen} onOpenChange={setIsBuySqrOpen} />
    </>
  );
};

export default ProductsMenuButton;
